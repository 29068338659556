import { Link } from "react-router-dom";
import app from "../firebase/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import React from "react";

const db = getFirestore(app);

export interface TechItems {
  items: {
    id?: string;
    src?: string;
    title?: string;
  }[];
}

const RecentBlogs = () => {
  //getting tech Items`
  const [items, setItems] = React.useState<TechItems["items"]>([]);
  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const querySnapshot = await getDocs(collection(db, "techItems"));
      let dataList: TechItems["items"] = [];
      querySnapshot.forEach((doc) => {
        dataList.push(doc.data());
      });
      setItems(dataList);
    }
    fetchData();
  }, []);

  return (
    <div className="technologies-main">
      <div className="container">
        <div className="card">
          <h1>I'm good at</h1>
          <p>Below are some of the tools and technologies I utilize</p>
        </div>
        <div className="technologies-items">
          {items.map((item) => (
            <div className="technologies-item">
              <Link to="">
                <img src={item.src} alt={item.title} title={item.title} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default RecentBlogs;
