import Buttons from "./Elements/Button";
import LetstalkModal from "./Elements/LetstalkModal";
import {
  collection,
  deleteDoc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import app from "../firebase/firebase";
import styled from "styled-components";
import { AiOutlineCloseSquare } from "react-icons/ai";

const db = getFirestore(app);

const StyledFormTitle = styled.h1`
  color: ${({ theme }) => theme.text};
`;
const StyledFormInputs = styled.div`
  color: ${({ theme }) => theme.text};
`;

const StyledInput = styled.input`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputSelect = styled.select`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputTextArea = styled.textarea`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledSubmitMessageDiv = styled.div`
  /* background:black;  */
`;
const AdminAddPhotographsForm = () => {
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState({
    category: "",
  });
  const [categoryItems, setCategoryItems] = useState([]);

  const [modalShown, toggleModal] = useState(false);
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    console.log(input);
  };
  const btnClickHandler = () /*: Promise<void> */ => {
    // getting photographs
    toggleModal(!modalShown);
    if (input.category === "") {
      setIsInputEmpty(true);
      setFormSubmited(false);
      return;
    }
    setIsInputEmpty(false);
    const cityRef = doc(db, "photographyCategories", input.category);
    setDoc(cityRef, { category: input.category }, { merge: true });
    console.log("submited and stored safely");
    setFormSubmited(true);
    setInput({
      category: "",
    });
    fetchCategoryData();
  };
  const clickHandler = () => {
    toggleModal(false);
  };
  async function fetchCategoryData() {
    setIsLoading(true);
    // You can await here
    const querySnapshot = await getDocs(
      collection(db, "photographyCategories")
    );
    let dataList = [];
    querySnapshot.forEach((doc) => {
      return dataList.push(doc.data());
    });
    setCategoryItems(dataList);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchCategoryData();
  }, []);
  const deleteCategoryHandler = (item) => {
    console.log(item);
    deleteDoc(doc(db, "photographyCategories", item));
    fetchCategoryData();
    console.log("deleted");
  };
  return (
    <>
      <div className="letstalkform-main">
        <div className="form-section">
          <StyledFormTitle>Add Photograph Category</StyledFormTitle>
          <StyledFormInputs className="form-inputs">
            <div className="form-message">
              <label htmlFor="message">Category Name</label>
              <br />
              <StyledInput
                onChange={handleChange}
                value={input.category}
                id="category"
                name="category"
                placeholder="i.e Wildlife"
              ></StyledInput>
            </div>
          </StyledFormInputs>
        </div>
        <div>
          <Buttons
            type=""
            buttonTitle="Add"
            btnClickHandler={btnClickHandler}
          />
        </div>
        <div className="availableCategorySection">
          <h2>Available Category</h2>
          {isLoading && <p>Loading..</p>}
          <div className="availableCategoryList">
            {categoryItems.map((categoryItem) => (
              <div className="availableCategoryItem">
                <div>
                  <p>{categoryItem.category}</p>
                </div>
                <div>
                  <p>
                    <AiOutlineCloseSquare
                      onClick={() =>
                        deleteCategoryHandler(categoryItem.category)
                      }
                    />
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <StyledSubmitMessageDiv className="letstalk-StyledSubmitMessageDiv">
          {formSubmited && (
            <LetstalkModal
              message="Submitted Succesfully"
              id="success"
              modalShown={modalShown}
              clickHandler={clickHandler}
            />
          )}
          {isInputEmpty && (
            <LetstalkModal
              message="Please Fill All The Detail"
              id="failed"
              modalShown={modalShown}
              clickHandler={clickHandler}
            />
          )}
        </StyledSubmitMessageDiv>
      </div>
    </>
  );
};
export default AdminAddPhotographsForm;
