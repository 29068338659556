import { DefaultTheme } from "styled-components";

// export interface themeType extends DefaultTheme{
//   body?:String,
//   personalDetailsCardBackground?:string,
//   text?: string,
//   toggleBorder?:string,
//   gradient?:string,
//   buttonHoverColor?:string,
//   inputTypeBackground?:string,
//   blogItemOnHoverBackground?:string,
//   blogRightSectionBackground ?:'#fbfbfb'
// }
declare module "styled-components" {
  export interface DefaultTheme {
    body?: string;
    personalDetailsCardBackground?: string;
    text?: string;
    toggleBorder?: string;
    gradient?: string;
    buttonHoverColor?: string;
    inputTypeBackground?: string;
    blogItemOnHoverBackground?: string;
    blogRightSectionBackground?: string;
    HeaderLogoh1?: string;
  }
}
export const lightTheme: DefaultTheme = {
  body: "white",
  personalDetailsCardBackground: "#1b1b1b",
  text: "#1b1b1b",
  toggleBorder: "#FFF",
  gradient: "linear-gradient(#39598A, #79D7ED)",
  buttonHoverColor: "#1b1b1b",
  inputTypeBackground: "white",
  blogItemOnHoverBackground: "white",
  blogRightSectionBackground: "#fbfbfb",
};
export const darkTheme: DefaultTheme = {
  body: "#1b1b1b",
  text: "white",
  personalDetailsCardBackground: "#323232",
  toggleBorder: "#6B8096",
  gradient: "linear-gradient(#091236, #1E215D)",
  buttonHoverColor: "white",
  inputTypeBackground: "#c5c5c5",
  blogItemOnHoverBackground: "#e5e5e5",
  blogRightSectionBackground: "#c5c5c5",
};
