import Header from "../components/Header";
import { MdKeyboardArrowDown } from "react-icons/md";
import Footer from "../components/Footer";
import figmaIcon from "../images/kabirPlayingfootball.png";
import styled from "styled-components";
import atmiya from "../images/Atmiya_University-icon.png";
import charusat from "../images/CSPIT-icon.png";
import igenesys from "../images/iGenesys-icon.png";
import simform from "../images/simform-icon.jpeg";
import { HeaderProps } from "../App";
import { useState } from "react";

const StyledSliderTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  width: "89%";
  font-size: "3rem";
`;

const About = (props: HeaderProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imagesAfterLoad = () => {
    setIsLoaded(true);
  };
  return (
    <>
      <div className="container">
        <div className="about-section">
          <Header toggleTheme={props.toggleTheme} />
          <div className="about-slider">
            <div className="about-left">
              <StyledSliderTitle>
                Hello, I'm Hemil Rajpura, a frontend developer passionate about
                creating meaningful websites
              </StyledSliderTitle>
            </div>
            <div className="about-right">
              {!isLoaded ? (
                <img
                  src={
                    "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
                  }
                  srcSet={
                    "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
                  }
                  height={100}
                  width={100}
                  alt=""
                  onLoad={imagesAfterLoad}
                  loading="lazy"
                  style={{ filter: "grayscale(1)" }}
                />
              ) : (
                <img
                  src={figmaIcon}
                  alt="developer icon"
                  title="developer icon"
                />
              )}
            </div>
          </div>
          <div className="slider-down-arrow">
            <div className="center">
              <a href="#AboutSection">
                <div className="circle">
                  <MdKeyboardArrowDown className="down-arrow-icon" />
                  <div className="wave"></div>
                </div>
              </a>
            </div>
          </div>

          <div className="experience-section" id="AboutSection">
            <h1>Experience</h1>
            <div className="experience-items">
              <table>
                <tr>
                  <td className="experience-image">
                    <img src={simform} alt="" title="" />
                  </td>
                  <td className="experience-details">
                    <h2>Software Engineer</h2>
                    <h3>Simform Solutions</h3>
                    <h4>2022 may - present</h4>
                    <h4 className="experience-text">
                      After completing my training, I transitioned into a
                      full-time role at a company where I've been actively
                      involved in working on live projects
                    </h4>
                  </td>
                </tr>

                <tr>
                  <td className="experience-image">
                    <img src={simform} alt="" title="" />
                  </td>
                  <td className="experience-details">
                    <h2>Software Engineering Intern</h2>
                    <h3>Simform Solutions</h3>
                    <h4>2021 dec - 2022 may</h4>
                    <h4 className="experience-text">
                      During my training period, I delved into JavaScript
                      libraries such as React.js, Next.js, along with JavaScript
                      and TypeScript. I also gained proficiency in version
                      control using Git and GitHub
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td className="experience-image">
                    <img src={igenesys} alt="" title="" />
                  </td>
                  <td className="experience-details">
                    <h2>Web development Intern</h2>
                    <h3>I-Genesys</h3>
                    <h4>2021 jun - 2021 jul</h4>
                    <h4 className="experience-text">
                      During summer internship I learned web designing and
                      development using HTML, CSS, Javascript, and PHP
                    </h4>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className="education-section">
            <div className="experience-section">
              <h1>Education</h1>
              <div className="experience-items">
                <table>
                  <tr>
                    <td className="experience-image">
                      <img src={charusat} alt="" title="" />
                    </td>
                    <td className="experience-details">
                      <h2>BTech Computer Engineering</h2>
                      <h3>Charusat University</h3>
                      <h4>2019 jun - 2022 April</h4>
                      {/* <h4 className="experience-text">
                        After Diploma I wanted to explore more in computer
                        science so joined Charusat University for Bachelors.
                      </h4> */}
                    </td>
                  </tr>

                  <tr>
                    <td className="experience-image">
                      <img src={atmiya} alt="" title="" />
                    </td>
                    <td className="experience-details">
                      <h2>Diploma Computer Engineering</h2>
                      <h3>Atmiya University</h3>
                      <h4>2016 jun - 2019 jul</h4>
                      {/* <h4 className="experience-text">
                        After my SSC I decided to study Diploma in Computer
                        Engineering as i found the interest in exploring
                        computer languages.
                      </h4> */}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default About;
