import "./App.css";
import React, { useEffect, useState } from "react";
import Home from "./pages/Home";
import Letstalk from "./pages/Letstalk";
import Blog from "./pages/Blog";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import Collabrate from "./pages/Collabrate";
import About from "./pages/About";
import Photography from "./pages/Photography";
import Work from "./pages/Work";
import AdminAddPhoto from "./pages/AdminAddPhoto";
import AdminAddCategory from "./pages/AdminAddCategory";
import AdminLogin from "./pages/AdminLogin";

import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme/theme";
import { GlobalStyles } from "./theme/global";

import ReactGA from "react-ga";
import axios from "axios";
import { log } from "console";

export interface HeaderProps {
  toggleTheme?: () => void;
}

function App() {
  const [theme, setTheme] = useState("light");

  ReactGA.initialize("G-3CW41Z5W3P");
  // const location = useLocation();

  // useEffect(() => {
  //   ReactGA.pageview(location.pathname + location.search);
  // }, [location]);

  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === "light") {
      setTheme("dark");
      // otherwise, it should be light
    } else {
      setTheme("light");
    }
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyles />
        <Router>
          <Routes>
            <Route path="/" element={<Home toggleTheme={toggleTheme} />} />
            <Route path="/blog" element={<Blog toggleTheme={toggleTheme} />} />
            <Route
              path="/letstalk"
              element={<Letstalk toggleTheme={toggleTheme} />}
            />
            <Route
              path="/collabrate"
              element={<Collabrate toggleTheme={toggleTheme} />}
            />
            <Route
              path="/about"
              element={<About toggleTheme={toggleTheme} />}
            />
            <Route
              path="/photography"
              element={<Photography toggleTheme={toggleTheme} />}
            />
            <Route path="/work" element={<Work toggleTheme={toggleTheme} />} />
            <Route
              path="/adminaddphoto"
              element={<AdminAddPhoto toggleTheme={toggleTheme} />}
            />
            <Route
              path="/adminaddcategory"
              element={<AdminAddCategory toggleTheme={toggleTheme} />}
            />
            <Route
              path="/admin"
              element={<AdminLogin toggleTheme={toggleTheme} />}
            />
            <Route
              path="/*"
              element={<PageNotFound toggleTheme={toggleTheme} />}
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
