import BlogItem from "./BlogItem";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import readingBoyIcon from "../images/kabirBlogs.png";
import styled from "styled-components";
import React, { useState } from "react";
import app from "../firebase/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Skeleton from "@mui/material/Skeleton/Skeleton";
import { ItemProps } from "./BlogItem";
const db = getFirestore(app);
export interface BlogItemProps {
  item: ItemProps[];
}
const BlogSection = () => {
  const imagesAfterLoad = () => {
    setIsLoaded(true);
  };
  const StyledInput = styled.input`
    background: ${({ theme }) => theme.inputTypeBackground};
  `;
  const StyledMainRightDiv = styled.div`
    background: ${({ theme }) => theme.blogRightSectionBackground};
  `;
  const StyledParagraphBox = styled.p`
    color: ${({ theme }) => theme.text};
  `;
  const StyledNoBlogsFoundText = styled.h2`
    /* color: ${({ theme }) => theme.text};  */
    padding: 50px;
  `;
  const StyledFilterSpan = styled.span`
    /* color: ${({ theme }) => theme.text};  */
    padding: 50px;
  `;
  const [blogItems, setBlogItems] = React.useState<BlogItemProps["item"]>([]);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [blogFilter, setBlogFilter] = React.useState("all");
  const [filteredBlogItems, setFilteredBlogItems] =
    useState<BlogItemProps["item"]>(blogItems);
  //getting blogs
  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const querySnapshot = await getDocs(collection(db, "blogs"));
      let dataList: BlogItemProps["item"] = [];
      querySnapshot.forEach((doc) => {
        dataList.push(doc.data());
      });
      setBlogItems(dataList);
      setFilteredBlogItems(dataList);
      setIsLoaded(true);
    }
    fetchData();
  }, []);
  console.log(blogItems);

  const BGcolors = ["#4f4f4f94"];
  const techTagItems = [
    "all",
    "react",
    "javascript",
    "typescript",
    "laravel",
    "nodejs",
    "git",
    "django",
    "emberjs",
    "nextjs",
  ];

  const FilterHandler = (filterName: string) => {
    // setBlogFilter('');
    setBlogFilter(filterName);
    if (filterName === "all") {
      setFilteredBlogItems(blogItems);
      return;
    }

    setFilteredBlogItems(
      blogItems.filter((item) => {
        return item.tag === filterName;
      })
    );
    console.log(filteredBlogItems);
    console.log(blogFilter);
  };

  return (
    <>
      <div className="about-slider">
        <div className="about-left">
          <h1>
            Stay updated with the latest in development through insightful
            blogs. Discover recent articles here{" "}
          </h1>
        </div>
        <div className="about-right">
          {!isLoaded ? (
            <img
              src={
                "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
              }
              srcSet={
                "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
              }
              height={100}
              width={100}
              alt=""
              onLoad={imagesAfterLoad}
              loading="lazy"
              style={{ filter: "grayscale(1)" }}
            />
          ) : (
            <img
              src={readingBoyIcon}
              alt="developer icon"
              title="developer icon"
            />
          )}
        </div>
      </div>
      <div className="slider-down-arrow">
        <div className="center">
          <a href="#BlogSection">
            <div className="circle">
              <MdKeyboardArrowDown className="down-arrow-icon" />
              <div className="wave"></div>
            </div>
          </a>
        </div>
      </div>

      <div className="blogsection-main" id="BlogSection">
        <div className="blog-left">
          {/* <StyledParagraphBox>Search</StyledParagraphBox>
                    <StyledInput id="blog-search" type="search" /> */}
          <StyledParagraphBox>Search By Tags</StyledParagraphBox>
          <div className="blog-tags">
            {techTagItems.map((item) => (
              <StyledFilterSpan
                style={{
                  backgroundColor: BGcolors[0],
                  borderColor: blogFilter === item ? "#3f3f3f" : "transparent",
                  borderWidth: "3px",
                }}
                onClick={() => FilterHandler(item)}
              >
                #{item}
              </StyledFilterSpan>
            ))}
          </div>
        </div>

        <StyledMainRightDiv className="blog-right">
          <h1>Blogs</h1>
          <div className="blog-list">
            {filteredBlogItems.length === 0 && (
              <StyledNoBlogsFoundText>
                No Blog Found For {blogFilter} Filter !
              </StyledNoBlogsFoundText>
            )}
            {isLoaded ? (
              filteredBlogItems.map((item) => (
                <a href={item.link ? item.link : ""}>
                  {" "}
                  <BlogItem
                    key={item.id}
                    title={item.title}
                    tag={item.tag}
                    desc={item.desc}
                    date={item.date}
                    readTime={item.readTime}
                  />{" "}
                </a>
              ))
            ) : (
              <>
                <Skeleton sx={{ bgcolor: "#fbfbfb" }} />
                <Skeleton sx={{ bgcolor: "#fbfbfb" }} />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton sx={{ bgcolor: "#fbfbfb" }} />
                <Skeleton sx={{ bgcolor: "#fbfbfb" }} />
              </>
            )}
          </div>
          <Link to="">
            Load More Blogs
            <HiOutlineArrowNarrowRight />
          </Link>
        </StyledMainRightDiv>
      </div>
    </>
  );
};
export default BlogSection;
