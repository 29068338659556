import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import styled from 'styled-components';

const StyledItemDiv = styled.div`
   :hover{  background: ${({ theme }) => theme.blogItemOnHoverBackground};
}
`;
export interface ItemProps {
    item?: string,
    desc?: string,
    date?: string,
    readTime?: string,
    tag?: string,
    title?: string,
    id?: number,
    key?: number,
    link?: string,
}
const BlogItem = (item: ItemProps) => {
    const { title, desc, date, readTime, tag } = item;
    return (
        <>
            <StyledItemDiv className="blogitem">
                <h1> {title} </h1>
                <p> {desc} </p>
                <p className="blog-date">{date}<span> / {readTime}</span></p>
                <p className="blog-tag">#{tag}</p>
                <a href="#blogItem" className="blog-readmore">Read the blog <HiOutlineArrowNarrowRight className="blog-rightArrow" /> </a>
            </StyledItemDiv>
        </>
    )
}
export default BlogItem;