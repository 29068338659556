import React from "react";
import styled from "styled-components";

export interface ButtonProps {
  type?: string;
  btnClickHandler?: () => void;
  buttonTitle: string;
  loadingIcon?: boolean;
}

const StyledButton = styled.a`
  padding: 10px 34px;
  border: 2px solid ${({ theme }) => theme.text};
  color: ${({ theme }) => theme.text};
  font-weight: bold;
  border-radius: 50px;
  margin: 15px 0;
  font-size: 20px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.text} 50%,
    ${({ theme }) => theme.body} 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.6s ease-out;
  display: inline-block;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.text};
    background-position: left bottom;
    color: ${({ theme }) => theme.body};
  }
  @media (max-width: 1023px) {
    padding: 6px 24px;
    font-size: 15px;
  }
`;
const Button: React.FC<ButtonProps> = (props) => {
  return (
    <>
      <div onClick={props.btnClickHandler}>
        <StyledButton type={props.type}>
          {props.loadingIcon ? "Processing..." : props.buttonTitle}
        </StyledButton>{" "}
      </div>
    </>
  );
};

export default Button;
