import * as React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ModalProps } from "../LetsTalkForm";
import { FcApproval } from "react-icons/fc";
import { TiWarning } from "react-icons/ti";
export interface ModalState {
  children: any;
  shown: boolean;
  close: () => void;
  id: string;
}

const Modal: React.FC<ModalState> = ({ children, shown, close, id }) => {
  return shown ? (
    <div className="modal-backdrop" onClick={close}>
      <div
        className="modal-content"
        id={id}
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        <div className="Modal-closeButton">
          <div onClick={close}>
            <AiOutlineClose />{" "}
          </div>
        </div>
        {children}
      </div>
    </div>
  ) : null;
};

const LetstalkModal: React.FC<ModalProps> = ({
  message,
  modalShown,
  clickHandler,
  id,
}) => {
  return (
    <Modal shown={modalShown} close={clickHandler} id={id}>
      <div id="modal-main">
        <div className="modal-content-details">
          {id === "success" ? <FcApproval /> : <TiWarning />}
          {id !== "success" && <h2>{message}</h2>}
          {id === "success" && (
            <>
              <div>
                <h2>{message}</h2>
                <h5>We will get back to you via email soon</h5>{" "}
              </div>
              <Link to="/">Back To Home</Link>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default LetstalkModal;
