import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/Auth.context";

import axios from "axios";
import Buttons from "../components/Elements/Button";
import { getFirestore } from "firebase/firestore";
import { useState } from "react";
import app from "../firebase/firebase";
import styled from "styled-components";
import AdminHeader from "../components/AdminHeader";
import { useNavigate } from "react-router-dom";

const db = getFirestore(app);

const StyledFormTitle = styled.h1`
  color: ${({ theme }) => theme.text};
`;
const StyledFormInputs = styled.div`
  color: ${({ theme }) => theme.text};
`;

const StyledInput = styled.input`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputTextArea = styled.textarea`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledSubmitMessageDiv = styled.div`
  /* background:black;  */
`;
const AdminLogin = (props) => {
  const {
    state: ContextState,
    login,
    sendOTPFromBackend,
  } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError } = ContextState;

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/adminaddphoto");
    }
  }, [isLoggedIn]);

  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const [otpSent, setOTPSent] = useState(false);

  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const [modalShown, toggleModal] = useState(false);
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };

  const VerifyOTPHandler = () => {
    login(input.password);
  };

  const sendOTPHandlerLocal = async () => {
    toggleModal(!modalShown);
    sendOTPFromBackend()
      .then(() => {
        setOTPSent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <AdminHeader toggleTheme={props.toggleTheme} />
        <div className="letstalkform-main">
          <div className="form-section">
            <StyledFormTitle>Admin Login</StyledFormTitle>
            <StyledFormInputs className="form-inputs">
              {!otpSent && (
                <div className="form-name" style={{ width: "100%" }}>
                  <label htmlFor="name">Email</label>
                  <br />
                  <StyledInput
                    onChange={handleChange}
                    value={input.category}
                    type="email"
                    id="email"
                    name="email"
                    placeholder=""
                  />
                  <br />
                  <br />
                </div>
              )}
              {otpSent && (
                <div className="form-name" style={{ width: "100%" }}>
                  <label htmlFor="name">OTP</label>
                  <br />
                  <StyledInput
                    disabled={!otpSent}
                    onChange={handleChange}
                    value={input.password}
                    type="password"
                    id="password"
                    name="password"
                    placeholder="*******"
                  />
                  <br />
                  <br />
                </div>
              )}
            </StyledFormInputs>
          </div>
          <div>
            <Buttons
              type=""
              buttonTitle={otpSent ? "Verify OTP" : "Send OTP"}
              btnClickHandler={otpSent ? VerifyOTPHandler : sendOTPHandlerLocal}
              loadingIcon={isLoginPending}
            />
          </div>
          <div>
            {loginError && <h5> {loginError.toString()}</h5>}
            {!loginError && !isLoggedIn && otpSent && (
              <h5>OTP sent to your registered number</h5>
            )}
            {isLoggedIn && console.warn("logged in") && <h5>Logged In</h5>}
            {isInputEmpty && <h5>Please Fill All the details properly</h5>}
          </div>
          {/* <StyledSubmitMessageDiv className="letstalk-StyledSubmitMessageDiv">
                    {isLoggedIn && <LetstalkModal message="Login Succesfully" id="success" modalShown={modalShown} clickHandler={clickHandler} />}
                    {isInputEmpty && <LetstalkModal message="Please Fill All The Detail" id="failed" modalShown={modalShown} clickHandler={clickHandler} />}
                </StyledSubmitMessageDiv> */}
        </div>
      </div>
    </>
  );
};
export default AdminLogin;
