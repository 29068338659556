import Buttons from "./Elements/Button";
import LetstalkModal from "./Elements/LetstalkModal";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import app from "../firebase/firebase";
import ToastMessageComponent from "../components/ToastMessageComponent";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const db = getFirestore(app);

export interface ModalProps {
  message: string;
  id: string;
  modalShown: boolean;
  clickHandler: () => void;
}

const StyledFormTitle = styled.h1`
  color: ${({ theme }) => theme.text};
`;
const StyledFormInputs = styled.div`
  color: ${({ theme }) => theme.text};
`;

const StyledInput = styled.input`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputTextArea = styled.textarea`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledSubmitMessageDiv = styled.div`
  /* background:black;  */
`;
const LetsTalkForm = () => {
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const [input, setInput] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [modalShown, toggleModal] = useState(false);
  const handleChange = (e: any) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const btnClickHandler = () /*: Promise<void> */ => {
    toggleModal(!modalShown);
    if (input.name === "" || input.email === "" || input.message === "") {
      setIsInputEmpty(true);
      setFormSubmited(false);
      return;
    }
    setIsInputEmpty(false);

    emailjs
      .send(
        "service_tgcdo4p",
        "template_5k273pi",
        {
          type: "Query",
          name: input.name,
          email: input.email,
          projectname: "",
          interest: "",
          message: input.message,
        },
        {
          publicKey: "cUCRA1VYvjEsdlxrE",
        }
      )
      .then(
        () => {
          emailjs
            .send(
              "service_tgcdo4p",
              "template_cbhfsrf",
              {
                type: "Query",
                to_email: input.email,
                to_name: input.name,
              },
              {
                publicKey: "cUCRA1VYvjEsdlxrE",
              }
            )
            .then(
              () => {},
              (error: any) => {
                console.log(error.text);
              }
            );
        },
        (error: any) => {
          console.log(error.text);
        }
      );

    const cityRef = doc(db, "Queries", input.name);
    setDoc(
      cityRef,
      { message: input.message, email: input.email },
      { merge: true }
    );
    setFormSubmited(true);
    setInput({
      name: "",
      message: "",
      email: "",
    });
  };
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        btnClickHandler();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  return (
    <>
      <div className="letstalkform-main">
        <div className="form-section">
          <StyledFormTitle>How can i help you today?</StyledFormTitle>
          <StyledFormInputs className="form-inputs">
            <div className="form-name">
              <label htmlFor="name">Name</label>
              <br />
              <StyledInput
                onChange={handleChange}
                value={input.name}
                type="text"
                id="name"
                name="name"
                placeholder=""
              />
              <br />
              <br />
            </div>

            <div className="form-email">
              <label htmlFor="email">Email</label>
              <br />
              <StyledInput
                onChange={handleChange}
                value={input.email}
                type="email"
                id="email"
                name="email"
                placeholder=""
              />
              <br />
              <br />
            </div>

            <div className="form-message">
              <label htmlFor="message">Your Message</label>
              <br />
              {/* <StyledInput type="textarea" id="message" name="message" placeholder="" /><br /><br /> */}
              <StyledInputTextArea
                onChange={handleChange}
                id="message"
                value={input.message}
                rows={4}
                cols={50}
                name="message"
                placeholder=""
              ></StyledInputTextArea>
            </div>
          </StyledFormInputs>
        </div>
        <div>
          <Buttons
            type=""
            buttonTitle="Submit"
            btnClickHandler={btnClickHandler}
          />
        </div>
        <StyledSubmitMessageDiv className="letstalk-StyledSubmitMessageDiv">
          <ToastMessageComponent
            type={"success"}
            shouldShowState={formSubmited}
            onCloseMethod={() => setFormSubmited(false)}
            message=" Received! Expect to hear back from us soon via email"
          />
          <ToastMessageComponent
            type={"error"}
            shouldShowState={isInputEmpty}
            onCloseMethod={() => setIsInputEmpty(false)}
            message="Please Fill All The Detail"
          />
        </StyledSubmitMessageDiv>
      </div>
    </>
  );
};
export default LetsTalkForm;
