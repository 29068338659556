import React, { useContext, useState } from "react";
import { AuthContext } from "../context/Auth.context";
import { Link } from "react-router-dom";
import { IoMoonSharp } from "react-icons/io5";
import styled, { useTheme } from "styled-components";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const StyledMainHeaderLi = styled.li`
  color: ${({ theme }) => theme.text};
  font-size: 20px;
`;
const AiOutlineMenuBox = styled.div``;

const StyledresponsiveMenuUl = styled.ul`
  display: grid;
  padding: 15px;
`;
const StyledMenuUl = styled.ul`
  justify-content: space-evenly;
  align-items: center;
  /* display: none; */
  z-index: 99;
  @media (max-width: 1023px) {
    display: none;
  }
`;
const StyledMainHeaderLiResponsive = styled.li`
  color: ${({ theme }) => theme.body};
  font-size: 20px;
  padding: 5px 0;
`;
const StyledMainMoonIconLi = styled.li`
  color: ${({ theme }) => theme.text};
  font-size: 35px;
  top: 35px;
`;
const StyledresponsiveMenuIcon = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 35px;
  width: 20%;
  right: 0;
  position: absolute;

  @media (max-width: 1023px) {
    position: relative;
  }
`;
const StyledLiHeaderLogo = styled.h1`
  font-size: 4rem;
  font-family: "Babylonica", cursive;
  font-weight: 600;
  color: ${({ theme }) => theme.text};
`;

const StyledresponsiveMenuBox = styled.div`
background - color: ${({ theme }) => theme.text};
color: ${({ theme }) => theme.body};
font - size: 35px;
position: absolute;
z - index: 9;
left: 0;
@media(max - width: 1023px) {
  left: -79px;
  opacity: 0.8;
}
`;
const AdminHeader = (props) => {
  const { state: ContextState, logout } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError } = ContextState;
  const [menuResponsive, setMenuResponsive] = useState(false);
  const SetMenuResponsiveHandler = () => {
    setMenuResponsive(() => !menuResponsive);
  };
  const theme = useTheme();
  let headerStyle = {
    textDecoration: "#1b1b1b9b underline",
  };

  return (
    <>
      {
        <div className="header-main">
          <div className="header-logo">
            <Link to="/">
              <StyledLiHeaderLogo>.Hemil</StyledLiHeaderLogo>
            </Link>
          </div>
          <div className="header-nav">
            <StyledMenuUl>
              {!isLoggedIn && (
                <>
                  <Link to="/admin">
                    <StyledMainHeaderLi>Login</StyledMainHeaderLi>{" "}
                  </Link>{" "}
                </>
              )}
              {isLoggedIn && (
                <>
                  <Link to="/admin">
                    <StyledMainHeaderLi>Login</StyledMainHeaderLi>
                  </Link>
                  <Link to="/adminaddphoto">
                    {" "}
                    <StyledMainHeaderLi
                      style={
                        window.location.pathname === "/about" ? headerStyle : {}
                      }
                    >
                      Photographs
                    </StyledMainHeaderLi>
                  </Link>
                  <Link to="/adminaddcategory">
                    {" "}
                    <StyledMainHeaderLi
                      style={
                        window.location.pathname === "/about" ? headerStyle : {}
                      }
                    >
                      Photo Category
                    </StyledMainHeaderLi>
                  </Link>
                  <Link to="/admin">
                    {" "}
                    <StyledMainHeaderLi
                      onClick={() => {
                        logout();
                        console.log("logout cliked");
                      }}
                      style={
                        window.location.pathname === "/work" ? headerStyle : {}
                      }
                    >
                      Logout
                    </StyledMainHeaderLi>
                  </Link>{" "}
                </>
              )}

              <Link to="" onClick={props.toggleTheme}>
                {" "}
                <StyledMainMoonIconLi>
                  <IoMoonSharp
                    className="header-moonIcon"
                    style={{ borderColor: `${theme.text} ` }}
                  />{" "}
                </StyledMainMoonIconLi>
              </Link>
            </StyledMenuUl>
            <StyledresponsiveMenuIcon className="responsive-menu-main">
              <AiOutlineMenuBox onClick={SetMenuResponsiveHandler}>
                {menuResponsive ? <AiOutlineClose /> : <AiOutlineMenu />}
              </AiOutlineMenuBox>
              {menuResponsive && (
                <StyledresponsiveMenuBox>
                  <StyledresponsiveMenuUl className="responsive-menu-ul">
                    <Link to="/">
                      <StyledMainHeaderLiResponsive>
                        Home
                      </StyledMainHeaderLiResponsive>{" "}
                    </Link>
                    <Link to="/about">
                      {" "}
                      <StyledMainHeaderLiResponsive
                        style={
                          window.location.pathname === "/about"
                            ? headerStyle
                            : null
                        }
                      >
                        About
                      </StyledMainHeaderLiResponsive>
                    </Link>
                    <Link to="/work">
                      {" "}
                      <StyledMainHeaderLiResponsive
                        style={
                          window.location.pathname === "/work"
                            ? headerStyle
                            : null
                        }
                      >
                        Work
                      </StyledMainHeaderLiResponsive>
                    </Link>
                    <Link to="/work">
                      <StyledMainHeaderLi
                        style={
                          window.location.pathname === "/work"
                            ? headerStyle
                            : {}
                        }
                      >
                        Logout
                      </StyledMainHeaderLi>
                    </Link>
                  </StyledresponsiveMenuUl>
                </StyledresponsiveMenuBox>
              )}
              <div className="responsive-darkMode-button">
                <Link to="" onClick={props.toggleTheme}>
                  {" "}
                  <StyledMainMoonIconLi>
                    <IoMoonSharp
                      className="header-moonIcon"
                      style={{ borderColor: `${theme.text} ` }}
                    />
                  </StyledMainMoonIconLi>
                </Link>
              </div>
            </StyledresponsiveMenuIcon>
          </div>
        </div>
      }
    </>
  );
};

export default AdminHeader;
