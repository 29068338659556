import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import runningKabirImage from "../images/kabirRunning.png";
import app from "../firebase/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

export interface Images {
  item: {
    category: string;
    src: string;
  }[];
}

export interface ImageCategory {
  item: {
    category: string;
  }[];
}
const db = getFirestore(app);

const ImageGalleryMainDiv = styled.div`
  margin: 50px 0;
`;
const StyledFilterbutton = styled.button`
  background: ${({ theme }) => theme.inputTypeBackground};
`;

const PhotographyGallery = () => {
  let Buttonstyle: any = {
    background: "#dfdfdf",
  };

  const imageOnLoad = () => {
    //setIsLoaded(false);
  };
  const [error, setError] = React.useState(null);
  const [filter, setFilter] = React.useState("all");
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [items, setItems] = React.useState<Images["item"]>([]);
  const [categoryItems, setCategoryItems] = React.useState<
    ImageCategory["item"]
  >([]);
  const [filterdItems, setFilterdItems] = useState(items);
  const imagesAfterLoad = () => {
    setIsLoaded(true);
  };
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempSrc] = useState("");

  const getImg = (src: string) => {
    setTempSrc(src);
    setModel(true);
  };
  const closeModel = () => {
    setModel(false);
  };

  function shuffleArray(array: any) {
    return array
      .map((a: any) => ({ sort: Math.random(), value: a }))
      .sort((a: any, b: any) => a.sort - b.sort)
      .map((a: any) => a.value);
  }

  // getting photographs and category list
  React.useEffect(() => {
    async function fetchImageData() {
      // You can await here
      const querySnapshot = await getDocs(collection(db, "photos"));
      let dataList: any = [];
      querySnapshot.forEach((doc) => {
        return dataList.push(doc.data());
      });
      dataList = shuffleArray(dataList);
      setItems(dataList);
      setFilterdItems(dataList);
      console.log(dataList);
    }
    async function fetchCategoryData() {
      // You can await here
      const querySnapshot = await getDocs(
        collection(db, "photographyCategories")
      );
      let dataList: any = [];
      querySnapshot.forEach((doc) => {
        return dataList.push(doc.data());
      });
      setCategoryItems(dataList);
    }

    fetchImageData();
    fetchCategoryData();
  }, []);

  setTimeout(imageOnLoad, 200);
  const escFunction = (event: { key: string }): void => {
    console.log("on mmodal close");
    if (event.key === "Escape") {
      closeModel();
    }
  };

  const FilterHandler = (filterName: string) => {
    setFilter("");
    setFilter(filterName);
    if (filterName === "all") {
      setFilterdItems(items);
      return;
    }

    setFilterdItems(
      items.filter((item) => {
        return item.category === filterName;
      })
    );
  };

  return (
    <div className="photographyGallery">
      <div className="about-slider">
        <div className="about-left">
          <h1>
            Capture the magic of moments that last forever. Explore my recent
            shutterbugs here.
          </h1>
        </div>
        <div className="about-right">
          <img
            src={runningKabirImage}
            alt="developer icon"
            title="developer icon"
          />
        </div>
      </div>

      <div className="slider-down-arrow">
        <div className="center">
          <a href="#PhotoGrallerySection">
            <div className="circle">
              <MdKeyboardArrowDown className="down-arrow-icon" />
              <div className="wave"></div>
            </div>
          </a>
        </div>
      </div>

      <div id="PhotoGrallerySection">
        {/* filter  */}
        <div className="photographyGallery-buttons">
          <StyledFilterbutton
            style={filter === "all" ? Buttonstyle : {}}
            onClick={() => FilterHandler("all")}
          >
            All
          </StyledFilterbutton>
          {categoryItems.map((categoryItem) => (
            <StyledFilterbutton
              style={filter === categoryItem.category ? Buttonstyle : {}}
              onClick={() => FilterHandler(categoryItem.category)}
              id={categoryItem.category}
            >
              {categoryItem.category}
            </StyledFilterbutton>
          ))}
        </div>
        {/* filter end */}
        <div className={model ? "model open" : " model"}>
          <AiOutlineClose onClick={closeModel} />
          <img src={tempImgSrc} alt="" onKeyDown={escFunction} />
        </div>
        <ImageGalleryMainDiv className="ImageGalleryMainDiv">
          <div className="gallery">
            {filterdItems.length === 0 && (
              <>
                <h2>No Images Found :(</h2>
              </>
            )}
            {!isLoaded
              ? filterdItems.map((item, index) => (
                  <div className="pics" key={index}>
                    <img
                      src={
                        "https://static.wixstatic.com/media/3ddd1e_4fdb79e722b04ce58384795f6337d46b~mv2.gif"
                      }
                      srcSet={
                        "https://static.wixstatic.com/media/3ddd1e_4fdb79e722b04ce58384795f6337d46b~mv2.gif"
                      }
                      alt=""
                      onLoad={imagesAfterLoad}
                      loading="lazy"
                      style={{ filter: "grayscale(1)" }}
                      onKeyDown={escFunction}
                    />
                  </div>
                ))
              : filterdItems.map((item, index) => (
                  <div
                    className="pics"
                    onClick={() => getImg(item.src)}
                    key={index}
                    onKeyDown={escFunction}
                  >
                    <img src={item.src} style={{ width: "100%" }} alt="" />
                  </div>
                ))}
          </div>
        </ImageGalleryMainDiv>
      </div>
    </div>
  );
};

export default PhotographyGallery;
