import { Link } from "react-router-dom";
import Buttons from "./Elements/Button";

const CollabrateCard = () => {
  return (
    <>
      <div id="collabratecard-section">
        <div className="container">
          <div id="collabratecard">
            <div className="card">
              <h1>Interested in working together ?</h1>
              <p>I am always ready to take opportunities, let's have a talk </p>
            </div>
            <Link to="/collabrate">
              {" "}
              <Buttons buttonTitle="Let's Connect" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollabrateCard;
