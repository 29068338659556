import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PhotographyGallery from '../components/PhotographyGallery'
import InstagramConnectCard from '../components/InstagramConnectCard'
import { HeaderProps } from '../App'

const Photography = (props:HeaderProps) => {
  return (
    <div>
      <div className="container">
        <Header toggleTheme={props.toggleTheme} />
        <PhotographyGallery />
        <InstagramConnectCard />
      </div>
      <Footer />
    </div>
  )
}

export default Photography