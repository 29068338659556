import { Snackbar, SnackbarContent } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import React from "react";

const ToastMessageComponent = ({
  type,
  message,
  shouldShowState,
  onCloseMethod,
}: {
  type: string;
  message: string;
  shouldShowState: boolean;
  onCloseMethod: () => void;
}) => {
  const bgColorStyle = type === "success" ? "#5cb85c" : "#EF5350";

  return (
    <Snackbar
      open={shouldShowState}
      autoHideDuration={6000}
      onClose={() => onCloseMethod()}
    >
      <SnackbarContent
        style={{ backgroundColor: bgColorStyle }}
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            {type === "error" && (
              <CancelIcon
                style={{
                  color: "white",
                  marginRight: "8px",
                  borderRadius: "50%",
                  padding: "4px",
                }}
              />
            )}
            {type === "success" && (
              <CheckCircleIcon
                style={{
                  color: "white",
                  marginRight: "8px",
                  borderRadius: "50%",
                  padding: "4px",
                }}
              />
            )}
            <span style={{ color: "white" }}>{message}</span>
          </div>
        }
      />
    </Snackbar>
  );
};

export default ToastMessageComponent;
