import Header from "../components/Header";
import CollabrateForm from "../components/CollabrateForm";
import { HeaderProps } from "../App";

const Collabrate = (props:HeaderProps)=>{
return(
    <>
     <div className="container">
        <Header toggleTheme={props.toggleTheme} />
        <CollabrateForm />
        </div>
    </>
)
}
export default Collabrate; 