import Header from "../components/Header";
import BlogSection from "../components/BlogSection";
import Footer from "../components/Footer";
import { HeaderProps } from "../App";
const Blog = (props:HeaderProps) => {
    return (
        <>
            <div className="container">
                <Header toggleTheme={props.toggleTheme} />
                <BlogSection />
            </div>
            <Footer/>

        </>
    )
}

export default Blog;