import { FiInstagram, FiMail, FiLinkedin,FiTwitter,FiGithub } from 'react-icons/fi';

const Footer=()=>{
return(
    <>
    <div className="footer-main">

    <h2>.Hemil</h2>
        <div className="footer-socialmedia">
           <a href="https://www.instagram.com/hemil_soni_photography"> <FiInstagram className='footer-socialmedia-icon' /> </a>
           <a href="mailto:iamhemilsoni@gmail.com"><FiMail className='footer-socialmedia-icon' /> </a>
           <a href="https://in.linkedin.com/in/hemil-rajpura-0670951a5"> <FiLinkedin className='footer-socialmedia-icon' /> </a>
           <a href="https://twitter.com/iamhemilsoni"><FiTwitter className='footer-socialmedia-icon' /> </a>
           <a href="https://github.com/hemilrajpura-simform/"><FiGithub className='footer-socialmedia-icon' /> </a>

        </div>
        <p className='footer-copyright'>Sketched By Me &#169; hemil rajpura </p>
    </div>
    </>
)
}
export default Footer