import React from 'react'
// @ts-ignore
import hemilImage from '../images/hemil-personal-image.jpg';
import styled from 'styled-components';

const StyledMainTitle = styled.h1`
color: ${({theme}) => theme.text}
`;
const Slider = () => {
  return (
    <div className='slider-main'>
      <div className='slider-heading'>
        <StyledMainTitle>
          Hi, I'm Hemil <br />Nice To See You :)
        </StyledMainTitle>
        {/* <h3>I like to develop meaningfull stuffs</h3> */}
        <p>Designer and Frontend Developer</p>
      </div>

      <div className='slider-icon'>
        {/* <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fff" d="M40,20.1C29,42.2,-18.6,40.2,-31.3,17.1C-44.1,-5.9,-22,-49.9,1.7,-48.9C25.5,-47.9,51,-1.9,40,20.1Z" transform="translate(100 100)" />
        </svg> */}
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/3f9052106543203.5f91fa2e4e14d.png" alt="" />
      </div>

    </div>
  )
}

export default Slider