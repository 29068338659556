import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/Auth.context";
import AdminAddPhotographsForm from "../components/AdminAddPhotographsForm";
import { HeaderProps } from "../App";
import AdminHeader from "../components/AdminHeader";
import PhotographsManagement from "../components/PhotographsManagement";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import app from "../firebase/firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore";
const db = getFirestore(app);

const Letstalk = (props) => {
  const { state: ContextState } = useContext(AuthContext);
  const { isLoginPending, isLoggedIn, loginError } = ContextState;
  const [lastCount, setLastCount] = useState(0);
  const [visitorsDetails, setVisitorsDetails] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/admin");
    }
  }, []);
  const washingtonRef = doc(db, "VisitorsDetails", "LatestDetails");
  async function updateDetails() {
    setLastCount(visitorsDetails[0].VisitorCount);
    await updateDoc(washingtonRef, {
      VisitorCount: visitorsDetails[0].VisitorCount + 1,
    });
  }
  async function fetchData() {
    // You can await here
    const querySnapshot = await getDocs(collection(db, "VisitorsDetails"));
    let dataList = [];
    querySnapshot.forEach((doc) => {
      dataList.push(doc.data());
    });
    setVisitorsDetails(dataList);
  }
  React.useEffect(() => {
    updateDetails();
    fetchData();
  }, []);
  console.log(visitorsDetails[0]);
  return (
    <div className="container">
      <AdminHeader toggleTheme={props.toggleTheme} />
      <AdminAddPhotographsForm />
      <PhotographsManagement />
      {/* <div>Total Website Visitors :{visitorsDetails[0]?.VisitorCount} </div> */}
    </div>
  );
};

export default Letstalk;
