import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import React, { useState } from "react";
import app from "../firebase/firebase";

const db = getFirestore(app);

const PhotographsManagement = () => {
  const [filter, setFilter] = useState("all");
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [categoryItems, setCategoryItems] = [];
  const [filterdItems, setFilterdItems] = useState(items);
  // getting photographs and category list
  async function fetchCategoryData() {
    // You can await here
    const querySnapshot = await getDocs(
      collection(db, "photographyCategories")
    );
    let dataList = [];
    querySnapshot.forEach((doc) => {
      return dataList.push(doc.data());
    });
    setCategoryItems(dataList);
  }
  async function fetchImageData() {
    // You can await here
    setIsLoaded(true);
    const querySnapshot = await getDocs(collection(db, "photos"));
    let dataList = [];
    querySnapshot.forEach((doc) => {
      return dataList.push({
        category: doc.data().category,
        imageID: doc.id,
        src: doc.data().src,
      });
    });
    setItems(dataList);
    setFilterdItems(dataList);
    setIsLoaded(false);
  }

  React.useEffect(() => {
    fetchImageData();
    fetchCategoryData();
  }, []);

  const deletePhotoHandler = async (imageID) => {
    const imageRef = doc(db, "photos", imageID); // Constructing DocumentReference
    try {
      await deleteDoc(imageRef); // Deleting the document
      console.log("Document successfully deleted!");
      fetchImageData();
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  return (
    <div className="photographsManagementList">
      <div className="photographsList">
        {isLoaded && <p>Loading..</p>}
        {items.map((image) => (
          <div className="photographsItem">
            <div>
              <img src={image.src} alt={"some"} width="200" />
            </div>
            <div>{image.category}</div>
            <div
              onClick={() => {
                deletePhotoHandler(image.imageID);
              }}
            >
              delete
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotographsManagement;
