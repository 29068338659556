import Buttons from "./Elements/Button";
import LetstalkModal from "./Elements/LetstalkModal";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import app from "../firebase/firebase";
import styled from "styled-components";

const db = getFirestore(app);

const StyledFormTitle = styled.h1`
  color: ${({ theme }) => theme.text};
`;
const StyledFormInputs = styled.div`
  color: ${({ theme }) => theme.text};
`;

const StyledInput = styled.input`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputSelect = styled.select`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledInputTextArea = styled.textarea`
  background: ${({ theme }) => theme.inputTypeBackground};
`;
const StyledSubmitMessageDiv = styled.div`
  /* background:black;  */
`;

const AdminAddPhotographsForm = () => {
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [formSubmited, setFormSubmited] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState({
    category: "Astro",
    src: "https://lh3.googleusercontent.com/d/",
  });
  const [categoryItems, setCategoryItems] = useState([]);

  const [modalShown, toggleModal] = useState(false);
  const handleChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
    console.log(input);
  };
  const btnClickHandler = () /*: Promise<void> */ => {
    toggleModal(!modalShown);
    if (input.category === "" || input.src === "") {
      setIsInputEmpty(true);
      setFormSubmited(false);
      return;
    }
    setIsInputEmpty(false);
    const randN = Math.random();
    console.log(input.category + randN);
    const cityRef = doc(db, "photos", input.category + randN);
    setDoc(
      cityRef,
      { category: input.category, src: input.src },
      { merge: true }
    );
    console.log("submited and stored safely");
    setFormSubmited(true);
    setInput({
      category: "Astro",
      src: "https://lh3.googleusercontent.com/d/",
    });
  };
  const clickHandler = () => {
    toggleModal(false);
  };
  useEffect(() => {
    async function fetchCategoryData() {
      setIsLoading(true);
      const querySnapshot = await getDocs(
        collection(db, "photographyCategories")
      );
      let dataList = [];
      querySnapshot.forEach((doc) => {
        return dataList.push(doc.data());
      });
      setCategoryItems(dataList);
      setIsLoading(false);
    }
    fetchCategoryData();
  }, []);
  return (
    <>
      <div className="letstalkform-main">
        <div className="form-section">
          <StyledFormTitle>Add Photograph</StyledFormTitle>
          <StyledFormInputs className="form-inputs">
            <div className="category">
              <label htmlFor="category">Category</label>
              <br />

              <StyledInputSelect
                onChange={handleChange}
                value={input.category}
                type="text"
                id="category"
                name="category"
              >
                {isLoading && (
                  <option value="LoadingState">Loading.......</option>
                )}
                {categoryItems.map((categoryItem) => (
                  <option value={categoryItem.category}>
                    {categoryItem.category}
                  </option>
                ))}
              </StyledInputSelect>
              <br />
              <br />
            </div>

            <div className="form-message">
              <label htmlFor="message">Image Path</label>
              <br />
              <StyledInputTextArea
                onChange={handleChange}
                id="src"
                value={input.src}
                rows={4}
                cols={50}
                name="src"
                placeholder="some /"
              ></StyledInputTextArea>
            </div>
          </StyledFormInputs>
        </div>
        <div>
          <Buttons
            type=""
            buttonTitle="Upload"
            btnClickHandler={btnClickHandler}
          />
        </div>
        <StyledSubmitMessageDiv className="letstalk-StyledSubmitMessageDiv">
          {formSubmited && (
            <LetstalkModal
              message="Submitted Succesfully"
              id="success"
              modalShown={modalShown}
              clickHandler={clickHandler}
            />
          )}
          {isInputEmpty && (
            <LetstalkModal
              message="Please Fill All The Detail"
              id="failed"
              modalShown={modalShown}
              clickHandler={clickHandler}
            />
          )}
        </StyledSubmitMessageDiv>
      </div>
    </>
  );
};
export default AdminAddPhotographsForm;
