import axios from "axios";
import React, { useState } from "react";

export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
};

export const ContextProvider = (props) => {
  const [state, setState] = useState(initialState);

  const setLoginPending = (isLoginPending) => setState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
  const setLoginError = (loginError) => setState({ loginError });

  const sendOTPFromBackend = async () => {
    setLoginPending(true);
    const apiUrl =
      "https://hemilrajpura-server.onrender.com/generate-admin-otp";
    try {
      const response = await axios.post(apiUrl, {
        email: "iamhemilsoni@gmail.com",
      });
      setLoginPending(false);
    } catch (error) {
      console.log(error);
      setLoginPending(false);
    }
  };

  const login = async (password) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    const apiUrl = "https://hemilrajpura-server.onrender.com/verify-admin-otp";
    try {
      setLoginPending(false);
      const response = await axios.post(apiUrl, {
        otp: password,
        email: "iamhemilsoni@gmail.com",
      });
      setLoginSuccess(true);
    } catch (error) {
      console.log("error", error);
      setLoginError(error);
    }
  };

  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
        sendOTPFromBackend,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
