import React from "react";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";

const DetailscardMain = styled.div`
  background-color: ${({ theme }) => theme.personalDetailsCardBackground};
`;

const PersonalDetailsCard = () => {
  return (
    <DetailscardMain className="detailscard-main">
      <div className="container">
        <div className="detailscard-text">
          <h2>Hi, I am Hemil Rajpura From India</h2>
          <p>
            I am passionate about crafting meaningful software experiences. My
            journey as a Software Engineer began in 2022 when I delved into
            ReactJS and NodeJS during my training. Since then, I've immersed
            myself in various JavaScript libraries and concepts to hone my
            skills. Additionally, I enjoy graphic design using tools like
            Photoshop and CorelDraw. From logos and posters to cards and
            thumbnails, I leverage my creativity to bring ideas to life
          </p>
          <p className="know-more-text">
            Know More
            <BsArrowRight className="know-more-icon" />
          </p>
        </div>
      </div>
    </DetailscardMain>
  );
};

export default PersonalDetailsCard;
