import Header from "../components/Header";
import LetsTalkForm from "../components/LetsTalkForm";
import { HeaderProps } from '../App'

const Letstalk = (props:HeaderProps) => {
    return (
        <div className="container">
            <Header toggleTheme={props.toggleTheme} />
            <LetsTalkForm />
        </div>
    )
}

export default Letstalk;