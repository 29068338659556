import React from 'react'
// import DomainCard from '../components/DomainCard'
import Header from '../components/Header'
import PersonalDetailsCard from '../components/PersonalDetailsCard'
import Slider from '../components/Slider'
import Footer from '../components/Footer'
import CollabrateCard from '../components/CollabrateCard'
// import RecentBlogs from '../components/RecentBlogs'
import TechnologiesCard from '../components/TechnologiesCard'
import { HeaderProps } from '../App'

const Home = (props:HeaderProps) => {
  return (
    <div className='home '>
      <div className='container'>
        <Header toggleTheme={props.toggleTheme} />
        <Slider />
        </div>
        <PersonalDetailsCard />
        <CollabrateCard />
        <TechnologiesCard />
        <Footer />
    </div>
  )
}

export default Home