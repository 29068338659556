import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi';
import {HeaderProps} from '../App'
const PageNotFound:React.FC<HeaderProps> = (props) => {
  return (
    <>
      <div className='slider-main'>
        <div className='slider-heading'>
          <h2 className='pagenotfound-h2'>
            Opps, Page Not Found :(
            <br /><br />  <Link to="/"><BiArrowBack className='backButton' /></Link>
          </h2>

        </div>



      </div>
    </>
  )
}
export default PageNotFound;