import React from "react";
import hemilIcon from "../images/hemilIconPNG.png";
const InstagramConnectCard = () => {
  return (
    <a
      href="https://www.instagram.com/hemil_soni_photography"
      target="_blank"
      className="InstagramConnectCardSection"
    >
      <div className="InstagramConnectCard">
        <div>
          <img src={hemilIcon} alt="Insta Logo" />
        </div>
        <div>
          <a href="/www.instagram.com/hemil_soni_photography">
            <p>@hemil_soni_photography</p>
          </a>
        </div>
      </div>
    </a>
  );
};

export default InstagramConnectCard;
