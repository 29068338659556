import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import workingKabirImage from "../images/work-slider-image.png";
const WorkSection = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imagesAfterLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div id="WorkSectionMain">
      <div className="photographyGallery">
        <div className="about-slider">
          <div className="about-left">
            <h1>Curious about what I built? Here is a list!</h1>
          </div>
          <div className="about-right">
            {!isLoaded ? (
              <img
                src={
                  "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
                }
                srcSet={
                  "https://sherwin.scene7.com/is/image/sw/color-swatch?_tparam_size=250,250&layer=comp&_tparam_color=C2C0BB"
                }
                height={100}
                width={100}
                alt=""
                onLoad={imagesAfterLoad}
                loading="lazy"
                style={{ filter: "grayscale(1)" }}
              />
            ) : (
              <img
                src={workingKabirImage}
                alt="developer icon"
                title="developer icon"
              />
            )}
          </div>
        </div>
        <div className="slider-down-arrow">
          <div className="center">
            <a href="#WorkSection">
              <div className="circle">
                <MdKeyboardArrowDown className="down-arrow-icon" />
                <div className="wave"></div>
              </div>
            </a>
          </div>
        </div>
        <div className="experience-section" id="WorkSection">
          <h1>Projects</h1>
          <div className="projects-section">
            <a
              // href="https://deploy-preview-2--famous-sherbet-7ec1c5.netlify.app/search"
              target="_blank"
            >
              <div className="projectItem">
                <div className="projectItem-img">
                  {" "}
                  <img src="https://news.tradimo.com/wp-content/uploads/2019/11/technicalanalysis.jpg" />
                </div>
                <div className="projectItem-desc">
                  {" "}
                  <h2>Stock Analysis</h2>
                  <div className="projectItem-techs">
                    <span>NextJS</span>
                    <span>Firebase</span>
                    <span>Netlify</span>
                    <span>React</span>
                  </div>
                </div>
              </div>
            </a>

            <a
              // href="https://stupendous-queijadas-13acfd.netlify.app/"
              target="_blank"
            >
              <div className="projectItem">
                <div className="projectItem-img">
                  <img src="https://pixlr.com/images/best-photo-editor-cover.jpg" />
                </div>
                <div className="projectItem-desc">
                  {" "}
                  <h2>Image Editing Tool</h2>
                  <div className="projectItem-techs">
                    <span>ReactJS</span>
                    <span>Firebase</span>
                    <span>Netlify</span>
                    <span>HTML/CSS</span>
                  </div>
                </div>
                {/* <p className="underDevelopment">under development</p> */}
              </div>
            </a>

            <a
              // href="https://stupendous-queijadas-13acfd.netlify.app/"
              target="_blank"
            >
              <div className="projectItem">
                <div className="projectItem-img">
                  <img src="https://i.insider.com/622bb572843ef30018e6ba98?width=1136&format=jpeg" />
                </div>
                <div className="projectItem-desc">
                  <h2>Webshot-Screenshot taker</h2>
                  <div className="projectItem-techs">
                    <span>ReactJS</span>
                    <span>NodeJS</span>
                  </div>
                </div>
                {/* <p className="underDevelopment">under development</p> */}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSection;
