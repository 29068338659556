import React from 'react'
import Header from '../components/Header'
// import Slider from '../components/Slider.tsx'
import Footer from '../components/Footer'
import WorkSection from '../components/WorkSection'
import { HeaderProps } from '../App'
const Work = (props:HeaderProps) => {
    return (
        <div className='Work-page'>

            <div className="container">
                <Header toggleTheme={props.toggleTheme} />
                <WorkSection />
            </div>
            <Footer />

        </div>
    )
}

export default Work